import React, { useEffect } from "react";

import ContactFormWithTitle from "@components/forms/contact-form-with-title";
import Hero from "@components/shared/hero";
import SEO from "@components/shared/seo";
import LayOut from "@components/layout";
import { graphql, PageProps } from "gatsby";
import { Query, SanityContactUsPageConnection } from "@graphql-types";

interface Data extends Query {
  altRegions: SanityContactUsPageConnection;
}
import { useRegionChanged } from "@util/hooks";

interface Props extends PageProps {
  data: Data;
  pageContext: any;
}

const ContactUs = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useRegionChanged("contact-us");

  const sanityContactUsPage = props.data.allSanityContactUsPage.nodes[0];
  const alternateRegions = props.data.altRegions.nodes.map(alt => alt._rawRegion);

  return (
    <>
      <SEO
        seoData={sanityContactUsPage.seo}
        alternateRegions={alternateRegions}
        slug={props.pageContext.slug}
      />

      <LayOut>
        <Hero singleHeroContent={sanityContactUsPage.hero} />

        <ContactFormWithTitle margin="0px auto 70px auto" showSocialLinks />
      </LayOut>
    </>
  );
};

export default ContactUs;

export const query = graphql`
  query contactQuery($iban: String) {
    allSanityContactUsPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
      }
    }
    altRegions: allSanityContactUsPage {
      nodes {
        _rawRegion(resolveReferences:{maxDepth:10})
      }
    }
  }
`;